import React, { useEffect, useState } from "react";
import Career from "../component/Career";
import CurrentOpening from "../component/CurrentOpening";
import Culture from "../component/Culture";
import WorkBenefit from "../component/WorkBenefit";
import Testimonial from "../component/Testimonial";
import Why from "../component/Why";
import ScrollAnimation from "../component/ScrollAnimation";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { getCareer } from "../services/api/api";

const OurWork = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the animation occurs only once
    threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
  });
  const [career, setCareer] = useState([]);
  useEffect(() => {
    getCareer().then((res) => {
      setCareer(res.data.result.data);
    });
  }, []);
  const imagePopUpAnimation = {
    hidden: {
      opacity: 0,
      scale: 0,
      // y: 100,
    },
    show: {
      opacity: 1,
      scale: 1,
      // x: 0,
      // y: 0,
    },
  };

  const headingAnimation = {
    hidden: {
      opacity: 0,
      x: -100,
      // y: 50,
    },
    show: {
      opacity: 1,
      x: 0,
    },
  };

  return (
    <>
      {career.length != 0 ? (
        <>
          <Career />
          <CurrentOpening items={career.current_opening} />
          <Culture items={career?.culture[0]} />
          <WorkBenefit />
          {/* <Testimonial /> */}
          <Why items={career?.why_codeflix[0]} />
          {/* <ScrollAnimation/>   */}
          <div className="team_bg">
            <div className="wrapper">
              <div className="team_wrap">
                <motion.div
                  variants={headingAnimation}
                  initial="hidden"
                  animate={inView ? "show" : "hidden"}
                  transition={{
                    // delay: 2,
                    duration: 1,
                    // delayChildren: 0.3,
                  }}
                  className="left_col"
                >
                  <h3>
                    Become a <span>Codeflix Web Team </span>Member Today
                  </h3>
                </motion.div>
                <div
                  className="right_col"
                  style={{
                    backgroundImage: "url(./assets/images/new/team_bg.svg)",
                  }}
                >
                  <motion.img
                    ref={ref}
                    variants={imagePopUpAnimation}
                    initial="hidden"
                    animate={inView ? "show" : "hidden"}
                    transition={{
                      delay: 1,
                      duration: 1,
                      // delayChildren: 0.3,
                    }}
                    src="./assets/images/new/team.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default OurWork;
