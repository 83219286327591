import { useEffect, useState } from "react";
import { getBlogDetail, getBlogs } from "../services/api/api";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

const BlogDetail = () => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    className: "blog_list",
    focusOnSelect: true,
    adaptiveHeight: false,
    prevArrow: (
      <div className="slickbtn slick-prev">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            transform="rotate(180 25.5 25.5)"
            fill="url(#paint0_linear_17_4)"
          ></circle>
          <path
            d="M21.414 26L31 26L31 24L21.414 24L25.707 19.707L24.293 18.293L17.586 25L24.293 31.707L25.707 30.293L21.414 26Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_4"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slickbtn slick-next">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            fill="url(#paint0_linear_17_8)"
          ></circle>
          <path
            d="M29.586 25L20 25L20 27L29.586 27L25.293 31.293L26.707 32.707L33.414 26L26.707 19.293L25.293 20.707L29.586 25Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_8"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };
  const [blog, setBlog] = useState([]);
  let { blogId } = useParams();
  useEffect(() => {
    getBlogDetail(blogId).then((res) => {
      setBlog(res.data.result);
    });
  }, []);
  console.log(blog);
  return (
    <>
      {blog.length != 0 ? (
        <>
          <div
            className="blog_banner blog-dtl"
            style={{ backgroundImage: "url(/assets/images/new/blog-dtl.png)" }}
          >
            <div className="wrapper">
              <div className="blog-dtl-block">
                <a href="/blogs">
                  <img src="/assets/images/new/arrow-blog.svg" alt="arrow" />
                </a>
                <img src={blog?.logo?.url} alt="blog" />
              </div>
            </div>
          </div>
          <div className="block-dtl">
            <div className="wrapper">
              <div className="social">
                <a href="#">
                  <img src="/assets/images/site/facebook.svg" alt="facebook" />
                </a>
                <a href="#">
                  <img src="/assets/images/site/Linkedin.svg" alt="facebook" />
                </a>
                <a href="#">
                  <img src="/assets/images/site/Instagram.svg" alt="facebook" />
                </a>
              </div>
              <div className="title">
                <div className="title-head">
                  {blog.tags.map((item, index) => {
                    return (
                      <a
                        data-aos="zoom-out-up"
                        href="#"
                        className="button-container-1 aos-init aos-animate"
                      >
                        <span className="mas">
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 15 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                              fill="#09C2C1"
                            ></path>
                          </svg>{" "}
                        </span>
                        <span className="mask_btn">
                          <svg
                            width="15"
                            height="10"
                            viewBox="0 0 15 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                              fill="white"
                            ></path>
                          </svg>{" "}
                          {item.name}
                        </span>
                      </a>
                    );
                  })}
                  <span>July 29, 2023</span>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: blog.page_content }}
                ></div>
              </div>
            </div>
          </div>
          {blog.article.length !== 0 ? (
            <div className="topics-sec">
              <div className="wrapper">
                <div className="topics-head">
                  <h3>
                    <svg
                      width="26"
                      height="17"
                      viewBox="0 0 26 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.8 14.9006C14.7431 13.4411 16 11.1173 16 8.5C16 5.88268 14.7431 3.55892 12.8 2.09939C14.137 1.0951 15.799 0.5 17.5999 0.5C22.0182 0.5 25.5999 4.08172 25.5999 8.5C25.5999 12.9183 22.0182 16.5 17.5999 16.5C15.799 16.5 14.137 15.9049 12.8 14.9006ZM12.8 14.9006C11.4629 15.9049 9.80096 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C9.80096 0.5 11.4629 1.0951 12.8 2.09939C10.8568 3.55892 9.59993 5.88268 9.59993 8.5C9.59993 11.1173 10.8568 13.4411 12.8 14.9006Z"
                        fill="url(#paint0_linear_893_327)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_893_327"
                          x1="-0.362264"
                          y1="7.3"
                          x2="25.6128"
                          y2="7.73767"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#09C2C1" />
                          <stop offset="1" stop-color="#1D85D8" />
                        </linearGradient>
                      </defs>
                    </svg>
                    related topics
                  </h3>
                  <a
                    data-aos="zoom-out-up"
                    href="#"
                    className="button-container-1 aos-init aos-animate"
                  >
                    <span className="mas">View More</span>
                    <span className="mask_btn">View More</span>
                  </a>
                </div>
                <div className="topics-block">
                  <Slider {...settings}>
                    {blog.article.map((item, index) => {
                      let page_content =
                        item.page_content?.match(/<h2>(.*?)<\/h2>/);
                      return (
                        <a href={`/blog/${item.id}`}>
                          <div className="topics-slider">
                            <div className="element">
                              <img src={item?.logo?.url} alt="blogs" />
                              <div className="slide-dtls">
                                {item.tags.map((tag, index) => {
                                  return <span>{tag.name}</span>;
                                })}
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: page_content[0],
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default BlogDetail;
