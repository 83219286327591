import React from "react";
import styled, { keyframes, css } from "styled-components";

function ScrollAnimation({ images }) {
  const row1 = [
    "./assets/images/life_at/1.jpg",
    "./assets/images/life_at/2.jpg",
    "./assets/images/life_at/3.jpg",
    "./assets/images/life_at/5.jpeg",
    "./assets/images/life_at/6.jpg",
    "./assets/images/life_at/7.jpeg",
    "./assets/images/life_at/8.jpg",
    "./assets/images/life_at/9.jpg",
  ];
  return (
    <Marquee>
      <MarqueeGroup>
        {images.map((el) => (
          <ImageGroup>
            <Image src={el.url} />
          </ImageGroup>
        ))}
      </MarqueeGroup>
      <MarqueeGroup>
        {images.map((el) => (
          <ImageGroup>
            <Image src={el.url} />
          </ImageGroup>
        ))}
      </MarqueeGroup>
    </Marquee>
  );
}

export default ScrollAnimation;

const Marquee = styled.div`
  display: flex;
  width: 2000px;
  overflow: hidden;
  user-select: none;
`;

const scrollX = keyframes`
  from {
    left: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const common = css`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 30s linear infinite;
`;

const MarqueeGroup = styled.div`
  ${common}
`;

const ImageGroup = styled.div`
  display: grid;
  place-items: center;
  height: clamp(20rem, 2rem + 40vmin, 200rem);
`;

const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 0.5rem;
  aspect-ratio: 16/9;
  padding: 5px 20px;
`;
