import axiosClient from "./axios";

export const visitStore = (data) => axiosClient.post(`app/visit`, data);
export const contactUs = (data) => axiosClient.post(`app/contact-us`, data);
export const getClients = (data) => axiosClient.get(`app/client`);
export const getProjects = (data) => axiosClient.get(`project`);
export const getHome = (data) => axiosClient.get(`app/home`);
export const getCareer = (data) => axiosClient.get(`app/career`);
export const getAboutUs = (data) => axiosClient.get(`app/about-us`);
export const getBlogs = (data) => axiosClient.get(`app/blogs`);
export const getBlogDetail = (data) =>
  axiosClient.get(`app/blog-detail/${data}`);
export const jobApplication = (data) =>
  axiosClient.post(`app/job-application`, data);
