import { useEffect, useState } from "react";
import { getBlogs } from "../services/api/api";
import BlogBanner from "../component/BlogBanner";
import BlogSlider from "../component/BlogSlider";
import BlogArticle from "../component/BlogArticle";

const BlogPage = () => {
  const [blog, setBlogs] = useState([]);
  useEffect(() => {
    getBlogs().then((res) => {
      setBlogs(res.data.result.data);
    });
  }, []);
  console.log(blog);
  return (
    <>
      {blog.length !== 0 ? (
        <>
          <BlogBanner items={blog.tags} />
          <BlogSlider items={blog.blogs} />
          <BlogArticle items={blog.article} />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default BlogPage;
