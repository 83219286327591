import React, { useState } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import CurrentOpeningForm from "./CurrentOpeningForm";

const CurrentOpening = ({ items }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Ensures the animation occurs only once
    threshold: 0.2, // Change as needed; it's the percentage of the element's visibility to trigger the animation
  });
  const [modal, setModal] = useState({ status: false, data: "" });
  const item1 = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 1,
        fade: "fadeIn",
      },
    },
  };

  const item2 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item3 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 1.5,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item4 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 2,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item5 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 2.5,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item6 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 3,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };
  const item7 = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 3.5,
        duration: 1,
        // fade: 'fadeIn'
      },
    },
  };

  const modelToogle = (title) => {
    if (modal.status) {
      document.body.classList.remove("model-fadeIn");
    } else {
      document.body.classList.add("model-fadeIn");
    }
    setModal({ status: !modal.status, data: title });
  };

  return (
    <>
      {modal.status ? (
        <CurrentOpeningForm modal={modal} modelToogle={modelToogle} />
      ) : (
        ""
      )}
      <div className="current_opening" ref={ref}>
        <div className="wrapper">
          <div className="jobs_wrap">
            <motion.div
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={item1}
              className="title"
            >
              <a
                data-aos="zoom-out-up"
                href="#"
                className="  button-container-1"
              >
                <span className="mas">
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                      fill="#09C2C1"
                    />
                  </svg>{" "}
                  Jobs
                </span>
                <span className="mask_btn">
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                      fill="white"
                    />
                  </svg>{" "}
                  Jobs
                </span>
              </a>
              <h2>
                Current <span>Openings</span>
              </h2>
              <p>
                Unlock your potential with us! At Codeflix Web LLP, we're on the
                lookout for talented individuals to join our dynamic team.
                Explore our latest job vacancies across various IT disciplines.
                Take the next step in your career and be part of our innovative
                journey.
              </p>
            </motion.div>
          </div>
          <div className="job_listing">
            {items.map((item, index) => {
              return (
                <motion.a
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={item2}
                  href="javascript:void(0)"
                  className="job_col"
                  onClick={(e) => modelToogle(item.title)}
                >
                  <div className="mask">
                    <div className="col">
                      <div className="image">
                        <img src={item.logo.url} alt={item.logo.url} />
                      </div>
                      <div className="detail">
                        <h3
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></h3>
                        <span className="time">Full-time</span>
                        <ul>
                          {item.tags.map((tag, index) => {
                            return <li>{tag.name}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mask_wrap">
                    <div className="col">
                      <div className="image">
                        <img src={item.logo.url} alt={item.logo.url} />
                      </div>
                      <div className="detail">
                        <h3
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></h3>
                        <span className="time">Full-time</span>
                        <ul>
                          {item.tags.map((tag, index) => {
                            return <li>{tag.name}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </motion.a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentOpening;
