import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from "./ScrollAnimation";

const Why = ({ items }) => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    prevArrow: (
      <button type="button" className="slick-prev">
        Previous
      </button>
    ),
    nextArrow: (
      <button type="button" className="slick-next">
        Next
      </button>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <div className="why">
      <div className="wrapper">
        <div className="title">
          <a data-aos="zoom-out-up" href="#" className="  button-container-1">
            <span className="mas">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="#09C2C1"
                />
              </svg>{" "}
              Why
            </span>
            <span className="mask_btn">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="white"
                />
              </svg>{" "}
              Why
            </span>
          </a>
          <h2
            data-aos="zoom-out-up"
            dangerouslySetInnerHTML={{ __html: items.title }}
          ></h2>
        </div>
      </div>
      <div className="why_slider">
        <ScrollAnimation images={items.logo} />
      </div>
      <div className="wrapper">
        <div className="why_detail_list">
          <div className="why_detail">
            <div className="detail">
              <p data-aos="zoom-out-up">
                Codeflix isn't just a workplace; it's a canvas where your career
                takes on new dimensions. We pride ourselves on curating
                meaningful and impactful projects that shape industries and
                drive innovation. As part of our close-knit team, you won't just
                be an employee – you'll play a pivotal role in every aspect of
                our journey.
              </p>
              <h4 data-aos="zoom-out-up">
                Come be a part of Codeflix and elevate your career to exciting
                new heights."
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
