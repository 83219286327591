import React, { useEffect, useState } from "react";
import { jobApplication } from "../services/api/api.js";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toast";
const CurrentOpeningForm = ({ modal, modelToogle }) => {
  const [color, setColor] = useState("#fff");
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [application, setApplication] = useState({
    name: "",
    reason: "",
    current_pkg: "",
    expected_pkg: "",
    available_date: "",
    resume: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    await jobApplication(application)
      .then((res) => {
        console.log(res);
        toast(res.data.message, {
          backgroundColor: "#323131",
          color: "#ffffff",
        });
        setLoading(false);
        modelToogle();
      })
      .catch((e, res) => {
        if (e.response.status == 422) {
          setError(e.response.data.result);
        }
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    setError([]);

    if (event.target.name == "resume") {
      setApplication({
        ...application,
        [event.target.name]: event.target.files[0],
      });
    } else {
      setApplication({
        ...application,
        [event.target.name]: event.target.value,
      });
    }
  };
  return (
    <div className="contact_us open_model">
      <div className="wrapper">
        <div className="contact_sec">
          <div className="close" onClick={modelToogle}>
            X
          </div>
          <div className="left_col">
            <div className="title">
              <h3 data-aos="zoom-out-up" className="aos-init aos-animate">
                <span>Apply for </span>{" "}
                <span dangerouslySetInnerHTML={{ __html: modal.data }}></span>
              </h3>
            </div>
            <div className="contact_form1">
              <form
                action=""
                method="post"
                onSubmit={handleSubmit}
                enctype="multipart/form-data"
              >
                <input
                  type="text"
                  name="name"
                  data-aos="zoom-out-up"
                  placeholder="Name"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                />
                <p>{error?.name}</p>
                <textarea
                  data-aos="zoom-out-up"
                  name="reason"
                  id="reason"
                  cols="10"
                  rows="1"
                  placeholder="Reason"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                ></textarea>
                <p>{error?.reason}</p>
                <input
                  type="text"
                  name="current_pkg"
                  data-aos="zoom-out-up"
                  placeholder="Current Package (in INR)"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                />
                <p>{error?.current_pkg}</p>
                <input
                  type="text"
                  name="expected_pkg"
                  data-aos="zoom-out-up"
                  placeholder="Expected Package (in INR)"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                />
                <p>{error?.expected_pkg}</p>
                <input
                  type="date"
                  name="available_date"
                  data-aos="zoom-out-up"
                  placeholder="You are available to join"
                  className="input_col aos-init aos-animate"
                  onChange={handleChange}
                />
                <p>{error?.available_date}</p>
                <div className="fileUpload">
                  <span>+ Add Your CV</span>
                  <input
                    type="file"
                    name="resume"
                    className="upload"
                    onChange={handleChange}
                  />
                </div>
                <p>
                  {application?.resume instanceof File ? "File Uploaded" : ""}
                </p>
                <p>{error?.resume}</p>
                <button
                  data-aos="zoom-out-up"
                  type="submit"
                  className="submitBtn aos-init aos-animate"
                  value=""
                  disabled={loading}
                  onMouseEnter={() => setColor("#000")}
                  onMouseLeave={() => setColor("#fff")}
                >
                  {loading ? (
                    <ThreeDots
                      height="16"
                      // width="80"
                      radius="9"
                      color={color}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass="three-dots-loading"
                      visible={loading}
                    />
                  ) : (
                    "Apply"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentOpeningForm;
