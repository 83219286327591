import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toast";
import { ThreeDots } from "react-loader-spinner";
import { contactUs } from "../services/api/api.js";
const ContactUs = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("#fff");
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleFormSubmit = () => {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("email", email);
    formData.append("message", message);

    return contactUs(formData);
  };
  const handleSubmit = async (e) => {
    // Prevent the default submit and page reload
    e.preventDefault();

    // Handle validations
    setLoading(true);
    await handleFormSubmit()
      .then((res) => {
        toast("Thank you for contacting us, we will get in thouch soon.", {
          backgroundColor: "#323131",
          color: "#ffffff",
        });
        setEmail("");
        setSubject("");
        setMessage("");
        setError("");
        setLoading(false);
      })
      .catch((e, res) => {
        if (e.response.status == 422) {
          setError(e.response.data.result);
          console.log(e.response.data.result);
          console.log(res);
        }
        setLoading(false);
      });
  };

  return (
    <div className="contact_us">
      <div className="wrapper">
        <div className="contact_sec">
          <div className="left_col">
            <div className="title">
              <h3 data-aos="zoom-out-up" className="aos-init aos-animate">
                <span>Got a project?</span>Let’s Have a Chat
              </h3>
            </div>
            <div className="contact_form1">
              <form action="" method="post" onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="email"
                  data-aos="zoom-out-up"
                  placeholder="Email"
                  className="input_col aos-init aos-animate"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <p>{error?.email}</p>
                <select
                  name="Subject"
                  data-aos="zoom-out-up"
                  className="input_col aos-init aos-animate"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                >
                  <option value="">Subject</option>
                  <option value="SEO">SEO</option>
                  <option value="App">App</option>
                  <option value="Website">Website</option>
                </select>

                <p>{error?.subject}</p>
                <textarea
                  data-aos="zoom-out-up"
                  name="message"
                  id="message"
                  cols="10"
                  rows="1"
                  placeholder="Message"
                  className="input_col aos-init aos-animate"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <p>{error?.message}</p>
                {/* <button
                    data-aos="zoom-out-up"
                    type="submit"
                    className="submitBtn aos-init aos-animate"
                    value="Get In Touch"
                  /> */}
                <button
                  data-aos="zoom-out-up"
                  type="submit"
                  className="submitBtn aos-init aos-animate"
                  value=""
                  onMouseEnter={() => setColor("#000")}
                  onMouseLeave={() => setColor("#fff")}
                  disabled={loading}
                >
                  {loading ? (
                    <ThreeDots
                      height="16"
                      // width="80"
                      radius="9"
                      color={color}
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass="three-dots-loading"
                      visible={loading}
                    />
                  ) : (
                    "Get In Touch"
                  )}
                </button>
              </form>
            </div>
          </div>
          <div className="right_col">
            <h4 data-aos="zoom-out-up" className="aos-init aos-animate">
              Contact Us
            </h4>
            <div className="contact_detail">
              <h5 data-aos="zoom-out-up" className="aos-init aos-animate">
                Office
              </h5>
              <p
                data-aos="zoom-out-up"
                className="address aos-init aos-animate"
              >
                407 Athena Avenue Bh- Jaguar showroom, Sarkhej - Gandhinagar
                Hwy, Gota, <br /> Ahmedabad, Gujarat <br /> 382481
              </p>
            </div>
            <div className="contact_detail">
              <h5 data-aos="zoom-out-up" className="aos-init aos-animate">
                Contact Info
              </h5>
              <p data-aos="zoom-out-up" className="tel aos-init aos-animate">
                Hardik Soni
              </p>
              <p data-aos="zoom-out-up" className="tel aos-init aos-animate">
                <a href="tel:+919998872716">+91 99988 72716</a>
              </p>
              <p data-aos="zoom-out-up" className="mailto aos-init aos-animate">
                <a href="mailto:info@codeflixweb.com">info@codeflixweb.com</a>
              </p>
            </div>
          </div>
          <div className="more-ctn">
            <h3>Contact Us</h3>
            <div className="more-block">
              <div className="more-card">
                <span>France - Office</span>
                <p>CODEFLIX SAS 1bis, Rue Stendhal, 75020 Paris, France</p>
              </div>
              {/* <div className='more-card'>
                  <span>Kuwait - Office</span>
                  <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
                </div>
                <div className='more-card'>
                  <span>Dubai - Office</span>
                  <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
