import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Culture = ({ items }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    nextArrow: (
      <button type="button" className="slickbtn slick-next slick-arrow">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.58606 5.99997L6.2693e-05 5.99997L6.26056e-05 7.99997L9.58606 7.99997L5.29306 12.293L6.70706 13.707L13.4141 6.99997L6.70706 0.292968L5.29306 1.70697L9.58606 5.99997Z"
            fill="black"
          ></path>
        </svg>
      </button>
    ),
    prevArrow: (
      <button type="button" className="slickbtn slick-prev slick-arrow">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.41394 8.00003L13.9999 8.00003L13.9999 6.00003L4.41394 6.00003L8.70694 1.70703L7.29294 0.293032L0.585938 7.00003L7.29294 13.707L8.70694 12.293L4.41394 8.00003Z"
            fill="black"
          ></path>
        </svg>
      </button>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <div className="culture">
      <div className="wrapper">
        <div className="culture_wrap">
          <a data-aos="zoom-out-up" href="#" className="  button-container-1">
            <span className="mas">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="#09C2C1"
                />
              </svg>{" "}
              culture
            </span>
            <span className="mask_btn">
              <svg
                width="15"
                height="10"
                viewBox="0 0 15 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                  fill="white"
                />
              </svg>{" "}
              culture
            </span>
          </a>
          <div className="culture_detail">
            <div className="title">
              <h2
                data-aos="zoom-out-up"
                dangerouslySetInnerHTML={{ __html: items.title }}
              ></h2>
              <p
                data-aos="zoom-out-up"
                dangerouslySetInnerHTML={{ __html: items.desc }}
              ></p>
            </div>
          </div>
        </div>
        <div className="culture_slider">
          <Slider {...settings}>
            {}
            <div className="slide_col">
              <div className="slide_item">
                <div className="image_full">
                  <div className="image">
                    <img
                      data-aos="zoom-out-up"
                      src={items.logo[0] ? items.logo[0].url : ""}
                      alt=""
                    />
                  </div>
                </div>
                <div className="image_half">
                  <div className="image">
                    <img
                      data-aos="zoom-out-up"
                      src={items.logo[1] ? items.logo[1].url : ""}
                      alt=""
                    />
                  </div>
                  <div className="image">
                    <img
                      data-aos="zoom-out-up"
                      src={items.logo[2] ? items.logo[2].url : ""}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Culture;
