import React from "react";

const MobileDevBanner = () => {
  return (
    <div
      class="inner_banner service-banner"
      style={{ backgroundImage: "url(/assets/images/site/mobilebanner.png)" }}
    >
      <div class="wrapper">
        <div class="banner_detail_1">
          <div class="details-ser">
            <h1 id="heading_line">Mobile App Development</h1>
            <h2 id="sub_heading1">
              Reference site about Lorem Ipsum, giving informationon its
              origins, as well as a random Lipsum generator.
            </h2>
          </div>
          <div class="arrows">
            <img src="/assets/images/new/arrows-ser.svg" alt="arrows" />
          </div>
          <div class="slider-block"></div>
        </div>
      </div>
    </div>
  );
};

export default MobileDevBanner;
