import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const BlogSlider = ({ items }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    className: "blog_list",
    focusOnSelect: true,
    adaptiveHeight: false,
    prevArrow: (
      <div className="slickbtn slick-prev">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            transform="rotate(180 25.5 25.5)"
            fill="url(#paint0_linear_17_4)"
          ></circle>
          <path
            d="M21.414 26L31 26L31 24L21.414 24L25.707 19.707L24.293 18.293L17.586 25L24.293 31.707L25.707 30.293L21.414 26Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_4"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    nextArrow: (
      <div className="slickbtn slick-next">
        <svg
          width="51"
          height="51"
          viewBox="0 0 51 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="25.5"
            cy="25.5"
            r="25.5"
            fill="url(#paint0_linear_17_8)"
          ></circle>
          <path
            d="M29.586 25L20 25L20 27L29.586 27L25.293 31.293L26.707 32.707L33.414 26L26.707 19.293L25.293 20.707L29.586 25Z"
            fill="white"
          ></path>
          <defs>
            <linearGradient
              id="paint0_linear_17_8"
              x1="-0.721698"
              y1="21.675"
              x2="51.0345"
              y2="22.22"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#09C2C1"></stop>
              <stop offset="1" stop-color="#1D85D8"></stop>
            </linearGradient>
          </defs>
        </svg>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };

  const navigate = useNavigate();

  return (
    <div className="blog_slider">
      <div className="wrapper">
        <Slider {...settings}>
          {items.map((item, index) => {
            return (
              <a href={`blog/${item.id}`}>
                <div className="blog_slide">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${item.logo.url})`,
                    }}
                  ></div>
                  <div className="detail">
                    <div className="title">
                      {item.tags.map((tag, index) => {
                        return (
                          <a
                            data-aos="zoom-out-up"
                            href="#"
                            className="  button-container-1"
                          >
                            <span className="mas">
                              <svg
                                width="15"
                                height="10"
                                viewBox="0 0 15 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                                  fill="#09C2C1"
                                />
                              </svg>
                              {tag.name}
                            </span>
                            <span className="mask_btn">
                              <svg
                                width="15"
                                height="10"
                                viewBox="0 0 15 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                                  fill="white"
                                />
                              </svg>
                              {tag.name}
                            </span>
                          </a>
                        );
                      })}

                      <div
                        dangerouslySetInnerHTML={{ __html: item.page_content }}
                      ></div>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};
export default BlogSlider;
