import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { getAboutUs } from "../services/api/api";

const About = () => {
  const [activeRow, setActiveRow] = useState(0);
  const controls = useAnimation();
  const [aboutUs, setAboutUs] = useState([]);
  useEffect(() => {
    getAboutUs().then((res) => {
      setAboutUs(res.data.result.data);
    });
  }, []);
  const headingAnimation = {
    hidden: {
      opacity: 0,
      x: -500,
      // y: 50,
    },
    show: {
      opacity: 1,
      x: 0,
    },
  };
  const imageAnimation = {
    hidden: {
      opacity: 0,
      x: 500,
      // y: 50,
    },
    show: {
      opacity: 1,
      x: 0,
    },
  };

  const handleRowHover = (rowId) => {
    setActiveRow(rowId);
  };
  const valueList = [
    {
      image: "./assets/images/new/value1.png",
      title: "Innovation",
      description:
        "At our core, we champion innovation as the heartbeat of our company. We merge cutting-edge technology with forward-thinking strategies, striving for excellence in every solution we craft.",
    },
    {
      image: "./assets/images/new/value2.png",
      title: "Collaboration",
      description:
        "Our success thrives on the power of collaboration. We foster an environment where diverse talents converge, sparking collective brilliance that propels us forward as a unified force.",
    },
    {
      image: "./assets/images/new/value3.png",
      title: "Quality",
      description:
        "Quality is the cornerstone of our work. With an unwavering commitment to precision, we meticulously craft products and services that exceed industry standards.",
    },
    {
      image: "./assets/images/new/value4.png",
      title: "Adaptability",
      description:
        "Adaptability is woven into our DNA. In a dynamic tech landscape, we thrive on agility, embracing change as an opportunity to evolve and innovate swiftly.",
    },
  ];

  // Function to start the animation
  const startAnimation = async () => {
    await controls.start({ opacity: 1, y: -100, transition: { duration: 3 } });
  };

  // Run the animation when the component mounts
  useEffect(() => {
    startAnimation();
  }, []);
  return (
    <>
      <div className="about_banner">
        <div className="wrapper">
          <div className="about_banner_detail">
            <motion.div
              variants={headingAnimation}
              initial="hidden"
              animate="show"
              transition={{
                // delay: 2,
                duration: 1,
                // delayChildren: 0.3,
              }}
              className="left_col"
            >
              <div className="title">
                <a
                  data-aos="zoom-out-up"
                  href="#"
                  className="  button-container-1"
                >
                  <span className="mas">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="#09C2C1"
                      />
                    </svg>{" "}
                    About Us
                  </span>
                  <span className="mask_btn">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="white"
                      />
                    </svg>{" "}
                    About Us
                  </span>
                </a>
                <h1 data-aos="zoom-out-up">
                  Pioneering Innovation and Embracing Challenges to Shape{" "}
                  <span> Tomorrow's Solutions.</span>
                </h1>
                <p data-aos="zoom-out-up">
                  Embark on a journey through our rich narrative, where
                  experiences intertwine to shape futures. We craft connections
                  and dreams, passionately exploring limitless horizons,
                  embracing every possibility along the way.
                </p>
              </div>
            </motion.div>
            <div className="right_col">
              <div className="about_bg" data-aos="zoom-out-up">
                <div className="wave_image">
                  {/* <motion.img
                                     animate={{ rotateY: 360, rotateY: 2 }} // Rotate the div 360 degrees
                                     transition={{ duration: 2, loop: Infinity }} // Set an infinite loop animation
                                     src="example.png"
                                    /> */}

                  <img src="./assets/images/new/about_wave.png" alt="" />
                </div>
                <div className="about_img">
                  <motion.img
                    variants={imageAnimation}
                    initial="hidden"
                    animate="show"
                    transition={{
                      delay: 1,
                      duration: 1,
                      // delayChildren: 0.3,
                    }}
                    alt="Animated Image"
                    src="./assets/images/new/about_bg.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="web_mobile_app">
        <div className="wrapper">
          <div className="web_mobile_detail">
            <div className="left_col">
              <div className="vision_list">
                <div className="vision_col">
                  <div className="head_title">
                    <svg
                      data-aos="zoom-out-up"
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.00003 8.60035C9.09304 7.77936 9.80005 6.47225 9.80005 5C9.80005 3.52775 9.09304 2.22064 8.00002 1.39965C8.75211 0.834743 9.68697 0.5 10.7 0.5C13.1853 0.5 15.2 2.51472 15.2 5C15.2 7.48528 13.1853 9.5 10.7 9.5C9.68696 9.5 8.75212 9.16526 8.00003 8.60035ZM8.00003 8.60035C7.24794 9.16526 6.31308 9.5 5.30005 9.5C2.81477 9.5 0.800049 7.48528 0.800049 5C0.800049 2.51472 2.81477 0.5 5.30005 0.5C6.31308 0.5 7.24793 0.834743 8.00002 1.39965C6.90701 2.22064 6.2 3.52775 6.2 5C6.2 6.47225 6.90701 7.77936 8.00003 8.60035Z"
                        fill="url(#paint0_linear_692_5660)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_692_5660"
                          x1="5.63755"
                          y1="1.34375"
                          x2="13.7938"
                          y2="9.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#09C2C1" />
                          <stop offset="1" stopColor="#1D85D8" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <h3 data-aos="zoom-out-up">Our Vision</h3>
                  </div>
                  <p data-aos="zoom-out-up">
                    The agency aspires to leverage its experience and deep
                    understanding of the business domains and needs of clients,
                    so as to offer customized solutions that can fit their
                    business requirements the best.
                  </p>
                </div>
                <div className="vision_col">
                  <div className="head_title">
                    <svg
                      data-aos="zoom-out-up"
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.00003 8.60035C9.09304 7.77936 9.80005 6.47225 9.80005 5C9.80005 3.52775 9.09304 2.22064 8.00002 1.39965C8.75211 0.834743 9.68697 0.5 10.7 0.5C13.1853 0.5 15.2 2.51472 15.2 5C15.2 7.48528 13.1853 9.5 10.7 9.5C9.68696 9.5 8.75212 9.16526 8.00003 8.60035ZM8.00003 8.60035C7.24794 9.16526 6.31308 9.5 5.30005 9.5C2.81477 9.5 0.800049 7.48528 0.800049 5C0.800049 2.51472 2.81477 0.5 5.30005 0.5C6.31308 0.5 7.24793 0.834743 8.00002 1.39965C6.90701 2.22064 6.2 3.52775 6.2 5C6.2 6.47225 6.90701 7.77936 8.00003 8.60035Z"
                        fill="url(#paint0_linear_692_5660)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_692_5660"
                          x1="5.63755"
                          y1="1.34375"
                          x2="13.7938"
                          y2="9.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#09C2C1" />
                          <stop offset="1" stopColor="#1D85D8" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <h3 data-aos="zoom-out-up">Our Goals</h3>
                  </div>
                  <p data-aos="zoom-out-up">
                    It was set up with the mission of making digital marketing
                    solutions accessible for maximum number of businesses,
                    easily as well as affordably. It aims to offer solutions
                    that are transformative and can have a major impact on the
                    bottom line of client organizations. The company is known
                    for highly responsive designs that load fast and work beyond
                    expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="right_col">
              <div className="title">
                <a
                  data-aos="zoom-out-up"
                  href="#"
                  className="  button-container-1"
                >
                  <span className="mas">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="#09C2C1"
                      />
                    </svg>{" "}
                    End Goals
                  </span>
                  <span className="mask_btn">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="white"
                      />
                    </svg>{" "}
                    End Goals
                  </span>
                </a>
                <h2>
                  Empowering Visionary Solutions for a{" "}
                  <span>Brighter Tomorrow </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="our_value">
        <div className="wrapper">
          <div className="our_value_detail">
            <div className="left_col">
              <div className="title">
                <a
                  data-aos="zoom-out-up"
                  href="#"
                  className="  button-container-1"
                >
                  <span className="mas">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="#09C2C1"
                      />
                    </svg>{" "}
                    Our Value
                  </span>
                  <span className="mask_btn">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="white"
                      />
                    </svg>{" "}
                    Our Value
                  </span>
                </a>
                <h2 data-aos="zoom-out-up">Values</h2>
                <p data-aos="zoom-out-up">
                  Our values center around innovation, inclusivity, and
                  excellence. We foster a culture that celebrates creativity.
                </p>
              </div>
            </div>
            <div className="right_col">
              <div className="value_list">
                {valueList.map((item, index) => {
                  return (
                    <div
                      className={`value_col ${
                        activeRow === index
                          ? "active aos-init aos-animate"
                          : "aos-init aos-animate"
                      }`}
                      data-aos="zoom-out-up"
                      onMouseEnter={() => {
                        handleRowHover(index);
                      }}
                    >
                      <div className="image">
                        <div
                          className="val_image"
                          style={{ backgroundImage: `url(${item.image})` }}
                        ></div>

                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="technology_index">
        <div className="wrapper">
          <div className="technology_detail">
            <div className="left_col">
              <div className="image">
                <div className="tech_image">
                  <img
                    src="./assets/images/new/technology.png"
                    data-aos="zoom-in"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="right_col">
              <div className="title">
                <a
                  data-aos="zoom-out-up"
                  href="#"
                  className="  button-container-1"
                >
                  <span className="mas">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="#09C2C1"
                      />
                    </svg>{" "}
                    Technology Index
                  </span>
                  <span className="mask_btn">
                    <svg
                      width="15"
                      height="10"
                      viewBox="0 0 15 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                        fill="white"
                      />
                    </svg>{" "}
                    Technology Index
                  </span>
                </a>
                <h2 data-aos="zoom-out-up">
                  <span>Improve and Innovate</span> with the tech trends
                </h2>
                <p data-aos="zoom-out-up">
                  Embrace the latest tech trends to fuel innovation and propel
                  your growth. Constant improvement through technology unlocks
                  endless possibilities for progress.
                </p>
              </div>
              <div className="processing_bar_list">
                <div className="prograss_bar" data-aos="zoom-out-up">
                  <p>
                    <span data-aos="zoom-out-up">Website Design</span>{" "}
                    <span data-aos="zoom-out-up">90%</span>
                  </p>
                  <div
                    id="Website_design"
                    data-aos="zoom-out-up"
                    style={{ width: "90%" }}
                  ></div>
                </div>
                <div className="prograss_bar" data-aos="zoom-out-up">
                  <p>
                    <span data-aos="zoom-out-up">Mobile Development</span>{" "}
                    <span data-aos="zoom-out-up">60%</span>
                  </p>
                  <div
                    id="Mobile_devlop"
                    data-aos="zoom-out-up"
                    style={{ width: "60%" }}
                  ></div>
                </div>
                <div className="prograss_bar" data-aos="zoom-out-up">
                  <p>
                    <span data-aos="zoom-out-up">UI / UX Design</span>{" "}
                    <span data-aos="zoom-out-up">20%</span>
                  </p>
                  <div
                    id="uiux_design"
                    data-aos="zoom-out-up"
                    style={{ width: "20%" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {aboutUs.length != 0 ? (
        <div className="life_at_codeflix">
          <div className="life_gallery">
            <div className="gallery_row">
              <div className="gallery_col">
                {aboutUs.slider1[0].logo.map((item, index) => {
                  return (
                    <div className="life_at_image">
                      <img src={item.url} alt={item.url} />
                    </div>
                  );
                })}
              </div>

              <div className="gallery_col gallery_col_reverse">
                {aboutUs.slider2[0].logo.map((item, index) => {
                  return (
                    <div className="life_at_image">
                      <img src={item.url} alt={item.url} />
                    </div>
                  );
                })}
              </div>

              <div className="gallery_col">
                {aboutUs.slider3[0].logo.map((item, index) => {
                  return (
                    <div className="life_at_image">
                      <img src={item.url} alt={item.url} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="life_at_detail">
              <div className="left_col">
                <div className="life_image">
                  <img
                    src={aboutUs?.life_at_codeflix[0]?.logo[0].url}
                    data-aos="zoom-out-up"
                    alt=""
                  />
                  <div className="detail">
                    <h4>Codeflix Diaries</h4>
                    <p>Revealing the Vibrant Life Beyond Lines of Code</p>
                  </div>
                </div>
              </div>
              <div className="right_col">
                <div className="title">
                  <a
                    data-aos="zoom-out-up"
                    href="#"
                    className="  button-container-1"
                  >
                    <span className="mas">
                      <svg
                        width="15"
                        height="10"
                        viewBox="0 0 15 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                          fill="#09C2C1"
                        />
                      </svg>{" "}
                      Gallery
                    </span>
                    <span className="mask_btn">
                      <svg
                        width="15"
                        height="10"
                        viewBox="0 0 15 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.19998 8.60035C8.29299 7.77936 9 6.47225 9 5C9 3.52775 8.29299 2.22064 7.19998 1.39965C7.95207 0.834743 8.88692 0.5 9.89995 0.5C12.3852 0.5 14.4 2.51472 14.4 5C14.4 7.48528 12.3852 9.5 9.89995 9.5C8.88692 9.5 7.95207 9.16526 7.19998 8.60035ZM7.19998 8.60035C6.44789 9.16526 5.51304 9.5 4.5 9.5C2.01472 9.5 0 7.48528 0 5C0 2.51472 2.01472 0.5 4.5 0.5C5.51303 0.5 6.44789 0.834743 7.19998 1.39965C6.10696 2.22064 5.39995 3.52775 5.39995 5C5.39995 6.47225 6.10697 7.77936 7.19998 8.60035Z"
                          fill="white"
                        />
                      </svg>{" "}
                      Gallery
                    </span>
                  </a>
                  <h2
                    data-aos="zoom-out-up"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.life_at_codeflix[0].title,
                    }}
                  ></h2>
                  <p
                    data-aos="zoom-out-up"
                    dangerouslySetInnerHTML={{
                      __html: aboutUs.life_at_codeflix[0].desc,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default About;
