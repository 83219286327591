const BlogArticle = ({ items }) => {
  const convertDateFormat = ({ dateString }) => {
    console.log(dateString);
    const dateObject = new Date(Date.parse(dateString));
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  };
  return (
    <div className="articles">
      <div className="wrapper">
        <div className="articles_title">
          <div className="left_col">
            <h3 data-aos="zoom-out-up">Articles</h3>
            <p data-aos="zoom-out-up">
              Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div>
          <div className="right_col">
            <a data-aos="zoom-out-up" href="#" className="  button-container-1">
              <span className="mas">
                View More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M10.3914 7.33333L4.00069 7.33333L4.00069 8.66666L10.3914 8.66666L7.52936 11.5287L8.47203 12.4713L12.9434 8L8.47203 3.52866L7.52936 4.47133L10.3914 7.33333Z"
                    fill="#09C2C1"
                  />
                </svg>
              </span>
              <span className="mask_btn">
                View More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M10.3914 7.33333L4.00069 7.33333L4.00069 8.66666L10.3914 8.66666L7.52936 11.5287L8.47203 12.4713L12.9434 8L8.47203 3.52866L7.52936 4.47133L10.3914 7.33333Z"
                    fill="white"
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
        <div className="article_list">
          <div className="article_row">
            {items.map((item, index) => {
              let page_content = item.page_content?.match(/<h2>(.*?)<\/h2>/);
              return (
              
                  <a href={`blog/${item.id}`}
                    className="article_col"
                    style={{
                      backgroundImage: `url(${item?.logo.url})`,
                    }}
                  >
                    <a href="#" className="tags">
                      {item?.tags[0].name}
                    </a>
                    <div className="blog-extra">
                      <div
                        className="detail"
                        dangerouslySetInnerHTML={{ __html: page_content[0] }}
                      ></div>
                      <div className="labels">
                        <a href="javascript:void(0)" className="author">
                          <img src="/assets/images/new/author.svg" alt="" />{" "}
                          <span>@codeflixweb</span>
                        </a>
                        <p>
                          {convertDateFormat({ dateString: item.created_at })}
                        </p>
                      </div>
                    </div>
                  </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogArticle;
