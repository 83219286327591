import React, { useEffect, useState } from "react";
import Banner from "../component/Banner";
import HomeAbout from "../component/HomeAbout";
import Service from "../component/Service";
import FeaturedWork from "../component/FeaturedWork";
import Trusted from "../component/Trusted";
import Testimonial from "../component/Testimonial";
import InnoSection from "../component/InnoSection";
import Blog from "../component/Blog";
import { getHome } from "../services/api/api";

const Home = () => {
  const [home, setHome] = useState([]);
  useEffect(() => {
    getHome().then((res) => {
      setHome(res.data.result.data);
    });
  }, []);
  return (
    <>
      {home.length != 0 ? (
        <>
          <Banner />
          <HomeAbout />
          <Service items={home?.service} />
          <FeaturedWork items={home?.work} />
          <Trusted />
          <Testimonial
            clientLogos={home?.testimonial[0]}
            clientLists={home?.client}
          />
          <InnoSection />
          <Blog items={home?.blog}/>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
